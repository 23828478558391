import React from "react"

const FooterLink = ({ imgSrc, altText, href, linkText }) => (
  <div className="footer__link-container">
    <a className="footer__link" alt={altText} href={href}>
      <img alt="GitHub profile link" class="footer__image" src={imgSrc}></img>
      {linkText}
    </a>
  </div>
)

export default FooterLink
