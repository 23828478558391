import React from "react"
import GithubSVG from "../images/github.svg"
import CodepenSVG from "../images/codepen.svg"
import LinkedInSVG from "../images/linkedin.svg"
import FooterLink from "./footerLink"

const Footer = () => (
  <footer>
    <div class="footer__contact">
      <FooterLink
        imgSrc={GithubSVG}
        altText="GitHub profile"
        href="https://github.com/susmitabhowmik"
        linkText="GitHub"
      />
      <FooterLink
        imgSrc={CodepenSVG}
        altText="Codepen profile"
        href="https://codepen.io/susmitabhowmik"
        linkText="CodePen"
      />
      <FooterLink
        imgSrc={LinkedInSVG}
        altText="LinkedIn profile"
        href="https://www.linkedin.com/in/susmita-bhowmik/"
        linkText="LinkedIn"
      />
    </div>

    <div class="footer__copywrite">&copy; Susmita Bhowmik</div>
  </footer>
)

export default Footer
